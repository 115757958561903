.deletedPost {
  position: absolute;
  z-index: 10;
  width: auto;
  font-size: 13px;
  border-radius: 9px;
  white-space: nowrap;
  color: white !important;
  background-color: #bf3531 !important;
  height: 28px;
  top: -12px;
}

.btnGray {
  color: rgb(127, 127, 127) !important;
  pointer-events: none !important;
  border: 1px solid transparent !important;
  cursor: none !important;
  -webkit-user-select: none !important;
  -webkit-touch-callout: none !important;
  font-size: 15px;
  background-color: rgb(151, 149, 146) !important;
  padding: 9px;
  border-radius: 8px;
  font-weight: bold;
}

.btnBlue {
  background-color: white !important;
  border: 1px solid rgb(0, 97, 254) !important;
  min-width: 168px;
  font-size: 14px;
  padding: 8px;
  border-radius: 8px;
  color: #0179ff;
  font-weight: bold !important;
}

.disabledChatBtn {
  color: #6c6c6c !important;
  pointer-events: none !important;
  border: 1px solid #6c6c6c !important;
  cursor: grab !important;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  font-size: 15px;
  padding: 9px;
  border-radius: 8px;
  font-weight: bold;
  background-color: white !important;
  height: 40px;
  width: 138px;
}

.favBtn {
  border: 2px solid #f2f2f7 !important;
}

.addonBtn {
  background-color: #ffffff !important;
  color: #0179ff !important;
  border: 1px solid #0179ff !important;
  font-weight: bold !important;
}

.paddingStart {
  padding-left: 0.6rem !important;
}

.blurred {
  filter: blur(2.2px) !important;
}

.whiteBg {
  background-color: #ffffff !important;
}
