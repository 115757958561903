/* Mobile Style */
.customModalDialog {
  max-width: 100% !important;
  height: auto !important;
  position: absolute !important;
  bottom: 0 !important;
  margin: 0 !important;
  border-radius: 10px 10px 0 0 !important;
  width: 100% !important;
  left: 0 !important;
}

.circleBorder {
  border: #d1d1d6 1.5px solid;
}

.closeButton {
  width: 34px;
  height: 33px;
}

.closeIcon {
  font-size: 22px;
  color: black;
}

.saveButtonMobile {
  margin: 16px auto;
  background-color: #0179ff;
  color: white;
  border: none;
  border-radius: 3px;
}

.saveFont {
  font-size: 17px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
}

.titleFont {
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
}

.genderFont {
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
}

.iconStyle {
  top: 65%;
  transform: translateY(-33%);
  color: black !important;
}

/* Desktop Style */
.DesktopModal {
  max-width: 650px;
}

.verifyButton {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 0 0.375rem 0.375rem 0;
}

.subTitle {
  font-size: 16px;
  color: #808080ff;
}

.labelSize {
  font-size: 15.4px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  margin-bottom: 5px;
}

.fontSizeAccountType {
  font-size: 15.5px;
}

.fontSizeAccountTypeSubTitle {
  font-size: 15.5px;
}

.fontSizeAccountTypeProfileSubTitle {
  font-size: 14.5px;
}

.datePickerFont {
  font-size: 16px;
}

.btnGray {
  color: #7f7f7f !important;
  pointer-events: none !important;
  border: 1px solid transparent;
  cursor: grab !important;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  font-size: 17px;
  background-color: #979592;
}

.saveButton {
  background-color: #0179ff;
  color: white;
  border: none;
  font-size: 16px;
}

.genderSelect {
  color: #333;
  cursor: pointer;

  & ul {
    right: 0;
    top: 100%;
  }
}

.genderSelect ul {
  max-height: 200px;
  z-index: 10;
  list-style: none;
  padding: 0 8px;
}

.genderSelect ul li {
  cursor: pointer;
}

.fakeRadioButtonWrapper {
  padding: 16px 8px;
}
.fakeRadioButtonWrapper .fakeRadioButton {
  width: 18px;
  height: 18px;
  margin-top: 3px;
  padding: 2px;
}

.fakeRadioButtonWrapper .fakeRadioButton span {
  width: 12px;
  height: 12px;
}
.fakeRadioButtonWrapper.active {
  transition: outline 0.1s ease, border-color 0.1s ease;
  outline: 2px solid #0061fe;
  border-color: #0061fe;
}

.fakeRadioButtonWrapper.active .fakeRadioButton {
  border-color: #0061fe !important;
}

.height50 {
  height: 50px;
}

.height41 {
  height: 41px;
}

