:root {
  --rounded-corners: 8px;
  --gray: #f5f5f5;
  --primary: #0179FF;
  --secondary: #9b59b6;
  --component-height: 200px;
  --shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  --rounded-corners-large: 16px;
}

.react-calendar {
  border-radius: var(--rounded-corners) !important;
  width: 335px !important;
  max-width: 100%;
  background: #fff;
  font: inherit;
  line-height: 1.125em !important;
  border: 0 !important;
  overflow: hidden !important;
  font-size: 14px;
  z-index: 50 !important;
}

abbr[title] {
  text-decoration: none !important;
  cursor: unset !important;
  outline: 0 !important;
  font-size: 11px !important;
}

.react-calendar__navigation__label {
  border: none !important;
  background-color: white !important;
}

.react-calendar button {
  padding: 10px !important;
  border: none !important;
}

.react-calendar button.react-calendar__century-view__decades__decade {
  padding: 8px !important;
  font-size: 15px;
}

.react-calendar button:disabled {
  background-color: white !important;
  color: #999 !important;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar__navigation {
  display: flex;
  gap: 5px;
  height: 44px;
  margin-bottom: 10px;
}

.react-calendar__navigation button:not(:has(span)) {
  min-width: 35px;
  background: none;
  font-size: 20px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers {
  font-weight: 700;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em calc(0.5em / 0.75);
}

.react-calendar__month-view__days__day--weekend {
  color: black !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--hasActive {
  height: 33.6px !important;
  background: #0179FF !important;
  color: #FFFFFF !important;
  border-radius: 8px !important;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
  color: #FFFFFF !important;
}

.react-calendar__tile--active {
  background: #0179FF;
  color: #fff;
  border-radius: 9px !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #0179FF;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.calender {
  z-index: 2;
}

.illustration > .react-date-picker {
  box-shadow: 4px 4px #0003;
}

.wrapper {
  width: 90%;
  max-width: 1110px;
  margin: 0 auto;
}

._main_kksd8_1 {
  margin-top: 4em;
}

._section_kksd8_5 {
  padding: 4em 0;
}

._section_kksd8_5:nth-of-type(odd) {
  background: var(--gray);
}

._section_kksd8_5:nth-of-type(odd) ._text_kksd8_13 {
  order: 1;
}

._section_kksd8_5:nth-of-type(odd) ._illustration_kksd8_17 {
  order: 2;
}

@media screen and (min-width: 780px) {
  ._section_kksd8_5:nth-of-type(2n) ._illustration_kksd8_17 {
    order: 1;
  }

  ._section_kksd8_5:nth-of-type(2n) ._text_kksd8_13 {
    order: 2;
  }
}

@media screen and (min-width: 780px) {
  ._section_kksd8_5 ._illustration_kksd8_17 {
    flex-basis: 300px;
  }
}

._title_kksd8_37 {
  margin-top: 0;
}

._wrapper_kksd8_41:after {
  content: '';
  display: block;
  clear: both;
}

._richContent_kksd8_53 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -1em;
}

._richContent_kksd8_53 > * {
  margin: 1em;
  max-width: 100%;
}

._text_kksd8_13 {
  flex-basis: 400px;
  flex-grow: 1;
  border: none;
}

._illustration_kksd8_17 {
  flex-basis: 90%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

._illustration_kksd8_17 img {
  width: 200px;
  height: 200px;
}

._container_7lb67_1 {
  padding: 0.5em 0;
  line-height: 30px;
}

._wrapper_7lb67_6 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

._title_7lb67_12 {
  margin: 0.5em 1em 0.5em 0;
}

._others_7lb67_17 select {
  width: 270px;
  height: 40px;
  display: inline-block;
  background: none;
  border: 0;
  border-radius: 25px;
  background-color: #0000001a;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="24" viewBox="0 0 32 24"><polygon points="0,0 32,0 16,24" fill="rgba%280, 0, 0, .5%29" /></svg>');
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: 244px 15px;
  padding: 0.4em 0.7em;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font: inherit;
}

._others_7lb67_17 select:hover {
  background-color: #0003;
}

._others_7lb67_17 select:focus {
  outline: none;
  border: 2px solid var(--primary);
  padding: calc(0.4em - 2px) calc(0.7em - 2px);
  background-position: 242px 13px;
}

._others_7lb67_17 select option {
  background: #ffffff;
}

._header_2716f_1 {
  display: flex;
  background: linear-gradient(130deg, var(--primary) 0, var(--secondary) 100%);
  overflow: hidden;
  font-weight: 600;
  color: #fff;
  padding-top: 5em;
  padding-bottom: calc(5em + (var(--component-height) / 2) - 1em);
}

._main_2716f_11 {
  margin-bottom: 0.325em;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
}

._sub_2716f_24 {
  margin-bottom: 1.3em;
}

._button_2716f_35 {
  display: inline-block;
  border-radius: 25px;
  background: #ffffff40;
  padding: 0.4em 0.7em;
  margin-right: 1em;
  margin-bottom: 1em;
}

._button_2716f_35:hover {
  background: #ffffff;
  color: var(--primary);
}

._button_2716f_35:focus {
  outline: none;
  border: 2px solid #ffffff;
  padding: calc(0.4em - 2px) calc(0.7em - 2px);
}

._iframeContainer_2716f_63 {
  display: inline-block;
  width: 150px;
  height: 40px;
  transform: scale(1.33);
  transform-origin: top left;
}

._iframeContainer_2716f_63 iframe {
  transform-origin: top left;
  transform: scale(1.4);
}

._wrapper_11qyc_1 {
  display: block;
  border-radius: 2px;
  margin: 1em 0;
  padding: 1em;
  background-color: #0000001a;
  position: relative;
}

._code_11qyc_10 {
  padding: 0;
}

._wrapper_11qyc_1:hover ._button_11qyc_14 {
  opacity: 1;
}

._button_11qyc_14 {
  width: 36px;
  height: 36px;
  position: absolute;
  top: calc(50% - 18px);
  right: 0.4em;
  border: 0;
  border-radius: 50%;
  background: none;
  line-height: 0.8;
  cursor: pointer;
}

._button_11qyc_14:hover {
  background-color: #00000080;
  color: #fff;
}

._button_11qyc_14:focus {
  width: 40px;
  height: 40px;
  outline: none;
  border: 2px solid #ffffff;
  top: calc(50% - 20px);
  right: calc(0.4em - 2px);
}

._button_11qyc_14:hover,
._button_11qyc_14:focus {
  opacity: 1;
}

._container_hmdp9_1 {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
}

._background_hmdp9_12 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000080;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

._wrapper_hmdp9_22 {
  box-shadow: var(--shadow);
  border-radius: var(--rounded-corners-large);
  position: relative;
  max-height: calc(100% - 10vw);
  min-width: 288px;
  border: 0;
  background: #fff;
  padding: 44px;
  margin: 5vw;
  overflow: auto;
  font: inherit;
  color: inherit;
}

._header_hmdp9_37 {
  display: flex;
  margin-bottom: 2em;
}

._header_hmdp9_37 h2 {
  margin-top: 0;
  margin-right: 1em;
}

._close_hmdp9_47 {
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  margin-top: -0.4em;
  margin-left: auto;
  margin-right: -0.4em;
  border: 0;
  border-radius: 50%;
  background: none;
  line-height: 0.8;
  cursor: pointer;
}

._close_hmdp9_47:hover {
  background-color: #00000080;
  color: #fff;
}

._close_hmdp9_47:focus {
  width: 48px;
  height: 48px;
  outline: none;
  border: 2px solid #ffffff;
  margin-top: calc(-0.4em - 2px);
  margin-right: calc(-0.4em - 2px);
}

._container_hmdp9_1 h2 {
  margin-bottom: 0.325em;
}

._container_hmdp9_1 h3 {
  margin-bottom: 0.325em;
}

._container_1ijpe_1 {
  width: 90%;
  max-width: 1110px;
  margin-top: calc(var(--component-height) / -2);
  margin-bottom: 4em;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}

._container_1ijpe_1 > * {
  box-shadow: var(--shadow);
}

._main_2hoi0_12 {
  margin-bottom: 0.325em;
  text-transform: uppercase;
}

._sub_2hoi0_24 {
  line-height: 2em;
}

._button_2hoi0_35:hover {
  background: #ffffff;
  color: var(--primary);
}

._button_2hoi0_35:focus {
  outline: none;
  border: 2px solid #ffffff;
  padding: calc(0.4em - 2px) calc(0.7em - 2px);
}
